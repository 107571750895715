const ecommerceNetworks = [
    { name: "Freshmania", state: "SP", url: "https://www.freshmania.com.br/b2c/c/verde-campo" },
    { name: "Trela", state: "SP", url: "https://trela.com.br/marca/verde-campo-1823?srsltid=AfmBOoraSWeAstO2uc8KF9eE7QDWaN6eyhiF4YZ_p84pz77jPJawIlsd" },
    { name: "Daki", state: "SP – MG", url: "https://soudaki.com/shop/search/?q=Verde%20Campo&origin=institucional" },
    { name: "Shopper", state: "SP", url: "https://landing.shopper.com.br/" },
    // { name: "Mercadinho São Luíz", state: "CE", url: "https://mercadinhossaoluiz.com.br/" },
    { name: "Natural da Terra", state: "SP", url: "https://www.naturaldaterra.com.br/s?q=Verde+Campo&fuzzy=0&operator=and&facets=fuzzy%2Coperator&sort=score_desc&page=0" },
    { name: "Mambo", state: "SP", url: "https://www.mambo.com.br/verde%20campo?_q=Verde%20Campo&map=ft" },
    { name: "Verdemar", state: "MG", url: "https://www.loja.verdemaratevoce.com.br/busca?termo=Verde%20Campo&departamento=0&page=1" },
    { name: "Zaffari", state: "RS", url: "https://www.zaffari.com.br/verde%20campo?map=ft" },
    { name: "Angeloni", state: "SC", url: "https://www.angeloni.com.br/super/verde%20campo?_q=verde%20campo&map=ft" },
    { name: "Super Muffato", state: "PR - SP", url: "https://www.supermuffato.com.br/verde%20campo?_q=Verde%20campo&map=ft" }
];

export default ecommerceNetworks;
